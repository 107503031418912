import React from "react";

export default function PurchaseSuccess({}) {
  return (
    <>
      <div className="bg-white min-h-full px-8 py-64 sm:px-12 sm:py-64 md:grid md:place-items-center">
        <div className="py-4 max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">🚀</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Purchase successful
                </h1>
                <p className="mt-3 text-xl text-gray-500">
                  Thank you for purchasing /meet for Slack.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <a
                  href="/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Go home
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
